import img from "../../images/other_services/pexels-michelangelo-buonarroti-4176543_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

import img1 from "../../images/other_services/pexels-egor-kamelev-1114318_.jpg";
import img2 from "../../images/other_services/pexels-mart-production-8458830_.jpg";
import img3 from "../../images/other_services/pexels-mart-production-8458861_.jpg";
import img4 from "../../images/other_services/pexels-mart-production-8459032_.jpg";
import img5 from "../../images/other_services/pexels-roger-brown-5149753_.jpg";

// data
const images = [
  {
    'title':'insetto',
    'img':img1,
  },{
    'title':'insetto',
    'img':img2,
  },{
    'title':'insetto',
    'img':img3,
  },{
    'title':'insetto',
    'img':img4,
  },{
    'title':'insetto',
    'img':img5,
  }
];

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul | Disinfestaioni</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di disinfestazioni" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Disinfestazioni " prepageName="Servizio" postpageName="Disinfestazioni"/>
      <Service
        title="DISINFESTAZIONI"
        text="Hai la casa o l’ambiente di lavoro infestato di animali, insetti non graditi? 
        Con Manutenpul puoi:"
        img={img}
        images={images}
      >
        <p>In alcuni periodi dell’anno il nostro ambiente lavorativo o domestico può essere invaso da nidi d’ape o vespe nei balconi, scarafaggi che vagano liberamente di notte e possono portare malattie. </p>
        
        <p>Zanzare e Mosche che assediano i giardini, e per chi non lo sapesse anche le cimici nei materassi.</p>
         
        <p>L’unica soluzione è chiamare un professionista di Manutenpul, specializzato nella disinfestazione Milano e non solo.Non vuoi rischiare di rovinare la tua moquette o il tuo pavimento? Al lavaggio e al trattamento con prodotti specifici ci pensiamo noi di Manutenpul."</p>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;